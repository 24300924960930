// import { Injectable } from "@angular/core";
// import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
// import { TokenService } from "../shared/token.service";

// @Injectable()

// export class AuthInterceptor implements HttpInterceptor {
//     constructor(private tokenService: TokenService) { }

//     intercept(req: HttpRequest<any>, next: HttpHandler) {
//         const accessToken = this.tokenService.getToken();
//         req = req.clone({
//             setHeaders: {
//                 ContentType: 'multipart/form-data',
//                 Authorization: "Bearer " + accessToken
//             }
//         });
//         return next.handle(req);
//     }
// }

import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
} from "@angular/common/http";
import { TokenService } from "./token.service";
import { catchError, tap } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const accessToken = this.tokenService.getToken();
    req = req.clone({
      setHeaders: {
        ContentType: "multipart/form-data",
        Authorization: "Bearer " + accessToken,
      },
    });
    return next.handle(req).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            console.log(" all looks good");
          }
        },
        (error) => {
          console.log(error);
          if (
            error?.status === 401 ||
            error?.error?.error == "Unauthenticated."
          ) {
            localStorage.clear();
            this.router.navigate(["login"]);
          } else if (
            error?.status === 403 &&
            error?.error?.message == "Forbidden"
          ) {
            localStorage.clear();
            this.router.navigate(["login"]);
          }
        }
      )
    );
  }
}
