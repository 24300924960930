import { INavData } from "@coreui/angular";

export const navItems: INavData[] = [
  {
    name: "Accueil",
    url: "/dashboard",
    icon: "icon-home",
  },
  {
    name: "Gestion des comptes",
    url: "/accounts",
    icon: "icon-briefcase",
    children: [
      {
        name: "Taux de reference",
        url: "/accounts/reference-rate-list",
        icon: "icon-book-open",
      },
      {
        name: "Liste des comptes",
        url: "/accounts/account-list",
        icon: "icon-book-open",
      },
      {
        name: "Ajouter un comtpe",
        url: "/accounts/add-compte",
        icon: "icon-note",
      },
    ],
  },
  {
    name: "Gestion de devise",
    url: "/devises/list",
    icon: "fa fa-euro",
  },
  {
    name: "Paiement",
    url: "/paiement",
    icon: "fa fa-money",
    children: [
      {
        name: "Employes",
        url: "/paiement/list",
        icon: "icon-user-follow",
        // badge:{
        //   text: 'string',
        //   variant: 'danger',
        // }
      },
      {
        name: "Evenements",
        url: "/paiement/evenements",
        icon: "icon-notebook",
        // badge:{
        //   text: 'string',
        //   variant: 'danger',
        // }
      },
    ],
  },

  {
    name: "PAYMENT STAT",
    url: "/stats",
    icon: "icon-notebook",
    children: [
      {
        name: "ATLPAY STAT",
        url: "/stats/atlpay",
        icon: "fa fa-calculator",
      },

      {
        name: "TEMPO STAT",
        url: "/stats/tempo-stat",
        icon: "fa fa-calculator",
      },

      {
        name: "PAYPAL STAT",
        url: "/stats/paypal",
        icon: "fa fa-calculator",
      },
    ],
  },

  {
    name: "Taux de changes",
    url: "/stats/currency",
    icon: "fa fa-calculator",
  },

  {
    name: "BANK STAT",
    url: "/bank/index",
    icon: "icon-notebook",
  },

  {
    name: "TEMPO",
    url: "/stats/tempo",
    icon: "fa fa-calculator",
  },

  {
    name: "Gestion de plafond",
    url: "/partners/params",
    icon: "icon-notebook",
  },

  {
    name: "Operations",
    url: "/operations",
    icon: "icon-refresh",
    children: [
      {
        name: "Transaction",
        url: "/operations/feed-account",
        icon: "icon-share-alt",
      },
      {
        name: "Multi-transaction",
        url: "/operations/multi-transaction",
        icon: "fa fa-plus-square-o",
      },
      {
        name: "Ajouter une facture",
        url: "/operations/send-euro",
        icon: "icon-loop",
      },
      {
        name: "Grand livre",
        url: "/operations/ledger-account",
        icon: "icon-layers",
      },
      {
        name: "Journal",
        url: "/operations/accounts-journal",
        icon: "icon-notebook",
      },
      {
        name: "Balance",
        url: "/operations/accounts-balance",
        icon: "icon-link",
      },
      /* {
        name: 'Dropdowns',
        url: '/operations/dropdowns',
        icon: 'icon-cursor'
      },
      {
        name: 'Brand',
        url: '/operations/brand-buttons',
        icon: 'icon-cursor'
      }*/
    ],
  },
  {
    name: "Rapport quotidien",
    url: "/operations/rapport-quotidien",
    icon: "icon-notebook",
  },

  {
    name: "Agences",
    url: "/cash",
    icon: "fa fa-bank",
    children: [
      {
        name: "Ria",
        url: "/ria",
        icon: "fa fa-exchange",
      },
      {
        name: "Juba express",
        url: "/juba",
        icon: "fa fa-exchange",
      },
      {
        name: "Terrapay",
        url: "/cash/terrapay",
        icon: "fa fa-exchange",
      },
      {
        name: "Remboursement",
        url: "/cash/remboursement",
        icon: "fa fa-exchange",
      },
      {
        name: "Gaza - غزة",
        url: "/cash/gaza",
        icon: "fa fa-exchange",
      },

      {
        name: "Mauritanie - موريتاني",
        url: "/cash/mauritanie",
        icon: "fa fa-exchange",
      },

      {
        name: "Imara - الإماره",
        url: "/cash/imara",
        icon: "fa fa-exchange",
      },

      {
        name: "Tadamoune - التضامن",
        url: "/cash/tadamoune",
        icon: "fa fa-exchange",
      },

      {
        name: "Mauripay - (موريبي)",
        url: "/cash/mauripay",
        icon: "fa fa-exchange",
      },

      {
        name: "CLICK - (كليك)",
        url: "/cash/click",
        icon: "fa fa-exchange",
      },

      {
        name: "SEDAD - (سداد)",
        url: "/cash/sedad",
        icon: "fa fa-exchange",
      },
      {
        name: "Bankily - بنكيلي",
        url: "/cash/bankily",
        icon: "fa fa-exchange",
      },

      {
        name: "Masrivy - مصرفي",
        url: "/cash/masrivy",
        icon: "fa fa-exchange",
      },

      {
        name: "Saleh - صالح",
        url: "/cash/saleh",
        icon: "fa fa-exchange",
      },
      {
        name: "Enejih",
        url: "/cash/enejih",
        icon: "fa fa-exchange",
      },
      {
        name: "Sagne ",
        url: "/cash/sagne",
        icon: "fa fa-exchange",
      },
      {
        name: "Ould Yenja - ولد ينج",
        url: "/cash/ouldyenja",
        icon: "fa fa-exchange",
      },

      {
        name: "Selibaby - سيليبابي",
        url: "/cash/selibaby",
        icon: "fa fa-exchange",
      },

      {
        name: "Tachout - تاشوط",
        url: "/cash/tachout",
        icon: "fa fa-exchange",
      },

      {
        name: "قلوب محسنة",
        url: "/cash/ghouloubmouhsine",
        icon: "fa fa-exchange",
      },
    ],
  },
];
