import { INavData } from "@coreui/angular";

export const navItems3: INavData[] = [
  {
    name: "Transfert",
    url: "/cash/transferts",
    icon: "fa fa-paper-plane",
  },

  {
    name: "Agences",
    url: "/cash",
    icon: "fa fa-bank",
    children: [
      {
        name: "Ria",
        url: "/ria",
        icon: "fa fa-exchange",
      },
      {
        name: "Juba express",
        url: "/juba",
        icon: "fa fa-exchange",
      },
      {
        name: "Terrapay",
        url: "/cash/terrapay",
        icon: "fa fa-exchange",
      },
      {
        name: "Remboursement",
        url: "/cash/remboursement",
        icon: "fa fa-exchange",
      },
      {
        name: "Gaza",
        url: "/cash/gaza",
        icon: "fa fa-exchange",
      },

      {
        name: "Mauritanie",
        url: "/cash/mauritanie",
        icon: "fa fa-exchange",
      },

      {
        name: "Imara",
        url: "/cash/imara",
        icon: "fa fa-exchange",
      },

      {
        name: "Tadamoune",
        url: "/cash/tadamoune",
        icon: "fa fa-exchange",
      },

      {
        name: "Mauripay - (موريبي)",
        url: "/cash/mauripay",
        icon: "fa fa-exchange",
      },
      {
        name: "CLICK - (كليك)",
        url: "/cash/click",
        icon: "fa fa-exchange",
      },
      {
        name: "SEDAD - (سداد)",
        url: "/cash/sedad",
        icon: "fa fa-exchange",
      },

      {
        name: "Bankily",
        url: "/cash/bankily",
        icon: "fa fa-exchange",
      },

      {
        name: "Masrivy",
        url: "/cash/masrivy",
        icon: "fa fa-exchange",
      },
      {
        name: "Saleh - صالح",
        url: "/cash/saleh",
        icon: "fa fa-exchange",
      },
      {
        name: "Enejih",
        url: "/cash/enejih",
        icon: "fa fa-exchange",
      },

      {
        name: "Sagne ",
        url: "/cash/sagne",
        icon: "fa fa-exchange",
      },
      {
        name: "Ould Yenja",
        url: "/cash/ouldyenja",
        icon: "fa fa-exchange",
      },

      {
        name: "Selibaby",
        url: "/cash/selibaby",
        icon: "fa fa-exchange",
      },

      {
        name: "Tachout",
        url: "/cash/tachout",
        icon: "fa fa-exchange",
      },

      {
        name: "Ghouloub mouhsine",
        url: "/cash/ghouloubmouhsine",
        icon: "fa fa-exchange",
      },
    ],
  },
  {
    name: "Mon journal",
    url: "/cash/accounts-journal",
    icon: "icon-notebook",
  },

  {
    name: "Demande de fonds",
    url: "/cash/fund-request",
    icon: "icon-notebook",
  },
];

export const navItems3Ar: INavData[] = [
  {
    name: "قائمة التحويلات",
    url: "/cash/transferts",
    icon: "fa fa-paper-plane",
  },

  {
    name: "وكالات",
    url: "/cash",
    icon: "fa fa-bank",
    children: [
      {
        name: "Ria",
        url: "/ria",
        icon: "fa fa-exchange",
      },
      {
        name: "Juba express",
        url: "/juba",
        icon: "fa fa-exchange",
      },
      {
        name: "Remboursement",
        url: "/cash/remboursement",
        icon: "fa fa-exchange",
      },
      {
        name: "غزة",
        url: "/cash/gaza",
        icon: "fa fa-exchange",
      },

      {
        name: "الموريتانية",
        url: "/cash/mauritanie",
        icon: "fa fa-exchange",
      },

      {
        name: "الإماره",
        url: "/cash/imara",
        icon: "fa fa-exchange",
      },

      {
        name: "التضامن",
        url: "/cash/tadamoune",
        icon: "fa fa-exchange",
      },

      {
        name: "بنكيلي",
        url: "/cash/bankily",
        icon: "fa fa-exchange",
      },

      {
        name: "مصرفي",
        url: "/cash/masrivy",
        icon: "fa fa-exchange",
      },
      {
        name: "إنجيه",
        url: "/cash/enejih",
        icon: "fa fa-exchange",
      },
      {
        name: "ساني",
        url: "/cash/sagne",
        icon: "fa fa-exchange",
      },
      {
        name: "ولد ينج",
        url: "/cash/ouldyenja",
        icon: "fa fa-exchange",
      },

      {
        name: "سيليبابي",
        url: "/cash/selibaby",
        icon: "fa fa-exchange",
      },

      {
        name: "تاشوط",
        url: "/cash/tachout",
        icon: "fa fa-exchange",
      },

      {
        name: "قلوب محسنة",
        url: "/cash/ghouloubmouhsine",
        icon: "fa fa-exchange",
      },
    ],
  },
  {
    name: "سجل معاملاتي",
    url: "/cash/accounts-journal",
    icon: "icon-notebook",
  },

  {
    name: "طلبات النقدية",
    url: "/cash/fund-request",
    icon: "icon-notebook",
  },
];
