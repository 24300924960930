import { INavData } from "@coreui/angular";

export let navItems1: INavData[] = [
  {
    name: "Accueil",
    url: "/dashboard",
    icon: "icon-home",
  },
  {
    name: "Utilisteurs",
    url: "/base",
    icon: "icon-people",
    children: [
      {
        name: "Create new user",
        url: "/base/new-user",
        icon: "icon-user-follow",
      },
      {
        name: "Users list",
        url: "/base/users-list",
        icon: "icon-people",
      },
    ],
  },
  {
    name: "Gestion de devise",
    url: "/devises/list",
    icon: "fa fa-euro",
  },
  {
    name: "Partners list",
    url: "/partners/list",
    icon: "icon-user-follow",
  },
  // {
  //   name: "Devises",
  //   url: "/devises/list",
  //   icon: "fa fa-euro",
  // },
  {
    name: "Etat de cadorim",
    url: "/situation/cadorim",
    icon: "fa fa-bar-chart",
  },
  {
    name: "Rapport transactions",
    url: "/situation/transaction",
    icon: "fa fa-bar-chart",
  },
  {
    name: "Paiement",
    url: "/paiement",
    icon: "fa fa-money",
    children: [
      {
        name: "Employes",
        url: "/paiement/list",
        icon: "icon-user-follow",
        // badge:{
        //   text: 'string',
        //   variant: 'danger',
        // }
      },
      {
        name: "Evenements",
        url: "/paiement/evenements",
        icon: "icon-notebook",
        // badge:{
        //   text: 'string',
        //   variant: 'danger',
        // }
      },
    ],
  },
  {
    name: "Taux de changes",
    url: "/stats/currency",
    icon: "fa fa-calculator",
  },
  {
    name: "TEMPO",
    url: "/stats/tempo",
    icon: "fa fa-calculator",
  },
  {
    name: "PAYMENT STAT",
    url: "/stats",
    icon: "icon-notebook",
    children: [
      {
        name: "ATLPAY STAT",
        url: "/stats/atlpay",
        icon: "fa fa-calculator",
      },

      {
        name: "TEMPO STAT",
        url: "/stats/tempo-stat",
        icon: "fa fa-calculator",
      },

      {
        name: "PAYPAL STAT",
        url: "/stats/paypal",
        icon: "fa fa-calculator",
      },
      {
        name: "STAT PROVIDER",
        url: "/stats/statprovider",
        icon: "fa fa-calculator",
      },
    ],
  },

  {
    name: "ACCOUNT STAT",
    url: "/situation/solde",
    icon: "fa fa-calculator",
  },
  {
    name: "Transfert",
    url: "/cash/transferts",
    icon: "fa fa-paper-plane",
  },

  {
    name: "Rapport quotidien",
    url: "/operations/rapport-quotidien",
    icon: "icon-notebook",
  },
  {
    name: "Journal",
    url: "/operations/accounts-journal",
    icon: "icon-notebook",
  },
  {
    name: "Soldes operateurs",
    url: "/cash/operateur-solde",
    icon: "icon-notebook",
  },

  {
    name: "Agence",
    url: "/cash",
    icon: "fa fa-bank",
    children: [
      {
        name: "Ria",
        url: "/ria",
        icon: "fa fa-exchange",
      },
      {
        name: "Juba express",
        url: "/juba",
        icon: "fa fa-exchange",
      },
      {
        name: "Terrapay",
        url: "/cash/terrapay",
        icon: "fa fa-exchange",
      },
      {
        name: "Remboursement",
        url: "/cash/remboursement",
        icon: "fa fa-exchange",
      },
      {
        name: "Gaza - غزة",
        url: "/cash/gaza",
        icon: "fa fa-exchange",
      },

      {
        name: "Mauritanie - موريتاني",
        url: "/cash/mauritanie",
        icon: "fa fa-exchange",
      },

      {
        name: "Imara - الإماره",
        url: "/cash/imara",
        icon: "fa fa-exchange",
      },

      {
        name: "Tadamoune - التضامن",
        url: "/cash/tadamoune",
        icon: "fa fa-exchange",
      },

      {
        name: "Mauripay - (موريبي)",
        url: "/cash/mauripay",
        icon: "fa fa-exchange",
      },

      {
        name: "CLICK - (كليك)",
        url: "/cash/click",
        icon: "fa fa-exchange",
      },
      {
        name: "SEDAD - (سداد)",
        url: "/cash/sedad",
        icon: "fa fa-exchange",
      },

      {
        name: "Bankily - بنكيلي",
        url: "/cash/bankily",
        icon: "fa fa-exchange",
      },

      {
        name: "Masrivy - مصرفي",
        url: "/cash/masrivy",
        icon: "fa fa-exchange",
      },
      {
        name: "Saleh - صالح",
        url: "/cash/saleh",
        icon: "fa fa-exchange",
      },
      {
        name: "Enejih",
        url: "/cash/enejih",
        icon: "fa fa-exchange",
      },
      {
        name: "Sagne ",
        url: "/cash/sagne",
        icon: "fa fa-exchange",
      },
      {
        name: "Ould Yenja - ولد ينج",
        url: "/cash/ouldyenja",
        icon: "fa fa-exchange",
      },

      {
        name: "Selibaby - سيليبابي",
        url: "/cash/selibaby",
        icon: "fa fa-exchange",
      },

      {
        name: "Tachout - تاشوط",
        url: "/cash/tachout",
        icon: "fa fa-exchange",
      },

      {
        name: "قلوب محسنة",
        url: "/cash/ghouloubmouhsine",
        icon: "fa fa-exchange",
      },
    ],
  },
];
