import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Import Containers
import { DefaultLayoutComponent } from "./containers";

import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { RegisterComponent } from "./views/register/register.component";
import { VerifyAuthenticatorComponent } from "./views/verify-authenticator/verify-authenticator.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404",
    },
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500",
    },
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login Page",
    },
  },

  {
    path: "verifyauthentication",
    component: VerifyAuthenticatorComponent,
    data: {
      title: "Verification User Page",
    },
  },
  {
    path: "register",
    component: RegisterComponent,
    data: {
      title: "Register Page",
    },
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    data: {
      title: "Home",
    },
    children: [
      {
        path: "base",
        loadChildren: () =>
          import("./views/base/base.module").then((m) => m.BaseModule),
      },
      {
        path: "situation",
        loadChildren: () =>
          import("./views/situation/situation.module").then(
            (m) => m.SituationModule
          ),
      },
      {
        path: "bank",
        loadChildren: () =>
          import("./views/bank/bank.module").then((m) => m.BankModule),
      },
      {
        path: "cadorimpay",
        loadChildren: () =>
          import("./views/cadorimpay/cadorimpay.module").then(
            (m) => m.CadorimPayModule
          ),
      },
      {
        path: "stats",
        loadChildren: () =>
          import("./views/stats/stats.module").then((m) => m.StatsModule),
      },
      {
        path: "operations",
        loadChildren: () =>
          import("./views/Operations/operations.module").then(
            (m) => m.OperationsModule
          ),
      },
      {
        path: "cash",
        loadChildren: () =>
          import("./views/cash/cash.module").then((m) => m.CashModule),
      },
      {
        path: "devises",
        loadChildren: () =>
          import("./views/devises/devises.module").then((m) => m.DevisesModule),
      },
      {
        path: "partners",
        loadChildren: () =>
          import("./views/partners/partners.module").then(
            (m) => m.PartnersModule
          ),
      },
      {
        path: "accounts",
        loadChildren: () =>
          import("./views/account-settings/account-settings.module").then(
            (m) => m.AccountSettingsModule
          ),
      },
      {
        path: "paiement",
        loadChildren: () =>
          import("./views/paiement/paiement.module").then(
            (m) => m.PaiementModule
          ),
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "ria",
        loadChildren: () =>
          import("./views/ria/ria.module").then((m) => m.RiaModule),
      },
      {
        path: "juba",
        loadChildren: () =>
          import("./views/juba/juba.module").then((m) => m.JubaModule),
      },

      {
        path: "notfound",
        component: P404Component,
      },
    ],
  },
  { path: "**", component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
